<template>
  <nav id="navbarExample" aria-label="Main navigation" class="navbar navbar-expand-lg fixed-top navbar-light">
    <div class="container">
      <a class="navbar-brand logo-text" href="#">
        <img :src="require('@/assets/logo.png')" alt="logoDiskola" class="img_logo" />
        <span>diskola.id</span>
      </a>
      <button id="navbarSideCollapse" aria-label="Toggle navigation" class="navbar-toggler p-0 border-0" type="button">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbarsExampleDefault" class="navbar-collapse offcanvas-collapse">
        <ul class="navbar-nav m-auto navbar-nav-scroll">
          <li class="nav-item">
            <router-link aria-current="page" class="nav-link" to="/"> Beranda</router-link>
          </li>
          <li class="nav-item">
            <router-link aria-current="page" class="nav-link" to="/produk"> Produk</router-link>
          </li>
          <li class="nav-item">
            <router-link aria-current="page" class="nav-link" to="/kebijakan-privasi">
              Kebijakan & Privasi
            </router-link>
          </li>
        </ul>
        <span class="nav-item">
          <a class="btn-solid-sm" href="https://api.whatsapp.com/send?text=hallo%20kak%3F" target="_blank">Kontak Kami</a>
        </span>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavbarHeader",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.img_logo {
  width: 25px;
}

.logo-text {
  display: contents;

  span {
    font-size: 16px;
    margin-left: 10px;
  }
}

.nav-item {
  .router-link-active {
    color: #04606c;
  }

  .router-link-exact-active {
    color: #04606c;
  }
}
</style>
