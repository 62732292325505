<template>
  <div class="home">
    <header id="header" class="header">
      <div class="container">
        <ImageAndText />
      </div>
    </header>
    <div id="pendidikan" class="cards-1 bg-gray">
      <div class="container p-3" style="border: 2px solid black; border-radius: 15px">
        <div class="row">
          <div class="col-lg-12">
            <h2>PENDIDIKAN ADALAH INVESTASI TERBESAR NEGARA</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-xl-7">
            <div class="image-container">
              <img class="img-fluid" :src="require('@/assets/pendidikan.png')" alt="image pendidikan" />
            </div>
          </div>
          <div class="col-lg-6 col-xl-5 margin-auto" style="text-align: left; margin: auto">
            <div class="text-container">
              <p class="p-large">
                Peran sistem dan Aplikasi Digital sangat menunjang untuk membentuk mutu pembelajaran Sekolah Cerdas
                (Smart School) dan menjadi bagian dari kemajuan Pendidikan di Indonesia.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="tentang-sekolah" class="cards-1 bg-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-xl-5 margin_auto">
            <div class="text-container">
              <h3 class="h3-large text_left">KENDALA SEKOLAH MENERAPKAN SMART SCHOOL</h3>
              <div>
                <p class="p-large text_left">
                  Hanya di Diskola dengan harga yang terjangkau & sistem yang efisien, kami memenuhi kebutuhan sekolah
                  yang unik & beragam.
                </p>
                <p class="p-large text_left">
                  diskola.id menghadirkan produk berkualitas melalui berbagai riset di divisi penelitian dan
                  pengembangan bisnis perusahaan sesuai problem yang ada di lapangan.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-7">
            <div class="image-container">
              <img class="img-fluid" :src="require('@/assets/pendidikan-adalah.png')" alt="image pendidikan face" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="solusi" class="cards-1 bg-gray bg_blue">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2 class="text-white">APA YANG KAMI LAKUKAN UNTUK MEMBANTU</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="card card_heigh bg-white p-3">
              <div class="card_icon">
                <img class="img-fluid" :src="require('@/assets/card1.png')" alt="image pendidikan face" />
              </div>
              <div class="card-body">
                <h5 class="card-title">Kemudahan pelayanan PPDB, PSB & Daftar Ulang untuk Pelajar</h5>
                <p>
                  Sistem layanan kami mendukung pelayanan dan pembayaran pendaftaran untuk Penerimaan Peserta Didik
                  baru, Penerimaan Siswa baru, dan Daftar ulang
                </p>
              </div>
            </div>

            <div class="card card_heigh bg-white p-3">
              <div class="card_icon red">
                <img class="img-fluid" :src="require('@/assets/card2.png')" alt="image pendidikan face" />
              </div>
              <div class="card-body">
                <h5 class="card-title">Aplikasi Pembelajaran Sekolah, Tugas Belajar & Ujian</h5>
                <p>
                  Pelaksanaan belajar dan ujian secara online untuk sekolah, berbasis aplikasi. Kami hadirkan Layanan
                  Materi pembelajaran, tugas dan ujian online
                </p>
              </div>
            </div>

            <div class="card card_heigh bg-white p-3">
              <div class="card_icon green">
                <img class="img-fluid" :src="require('@/assets/card3.png')" alt="image pendidikan face" />
              </div>
              <div class="card-body">
                <h5 class="card-title">Edukasi, Manajemen & Sistem Finansial Untuk Sekolah</h5>
                <p>
                  Dengan Diskola semua bisa mengakses layanan dengan mudah, semua dirancang agar mudah digunakan, mulai
                  dari guru, karyawan, siswa sampai dengan orang tua
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageAndText from "@/components/ImageAndText.vue";

export default {
  name: "HomeView",
  components: { ImageAndText },
};
</script>

<style>
.text_left {
  text-align: left;
}

.margin_auto {
  margin: auto;
}

.bg_blue {
  background: #04606c;
}

.card_heigh {
  @media (min-width: 992px) {
    height: 90%;
  }
  .card_icon {
    width: 100% !important;
    margin-bottom: 1.875rem !important;
    border-radius: 8px !important;
    /*background-color: #ddedfb;*/
    text-align: center !important;
  }
}
</style>
