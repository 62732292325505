<template>
  <div class="hello">
    <div class="row">
      <div class="col-lg-6 col-xl-5">
        <div class="text-container">
          <h1 class="h1-large">Disekolah Saja</h1>
          <p class="p-large">
            Kami menghadirkan layanan dan Integrasi terbaik PPDB, PSB, Daftar ulang, Pembelajaran Online, Rekap Absensi,
            Sistem Manajemen dan kebutuhan Transaksi - Pembayaran non tunai di Sekolah!
          </p>
          <a class="btn-solid-lg" href="https://api.whatsapp.com/send?text=hallo%20kak%3F" target="_blank"
            >Berlangganan...</a
          >
        </div>
      </div>
      <div class="col-lg-6 col-xl-7">
        <div class="image-container">
          <img class="img-fluid" :src="require('@/assets/home-awal.png')" alt="alternative" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageAndText",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
